import React from 'react'
import dynamic from 'next/dynamic'
import { withRouter } from 'next/router'
import validator from "../services/validation"
import ipdata from "../services/ipdata"
import mailer from "../services/mail"
import { MdArrowForward } from '@react-icons/all-files/md/MdArrowForward';
import SlackWebHook from "../services/slack";
import CareerMappingAction from "../actions/CareerMappingAction";
import { error, warn } from './Toast';
import { post } from "../services/fetch"
import CustomImage from './CustomImage'

class FreeConsultationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            location: "",
            service: "undergraduate",
            description: "",
            validator: false,
            disabled: false,
            locationDetails: '',
            careerMappingPrice: process.env.NEXT_PUBLIC_CAREER_MAPPING_PRICE === undefined ? 5000 : process.env.NEXT_PUBLIC_CAREER_MAPPING_PRICE,
            msgSent: false
        };
        this.consultationModal = null;
        this.successForm = null;
    }

    componentDidMount() {
        if(window.bootstrap) {
            this.consultationModal = new bootstrap.Modal(document.getElementById('freeConsultationModal'), {});
            this.successForm = new bootstrap.Modal(document.getElementById('successForm'), {});
        }
        ipdata.getLocation().then((res) => {
            if (res) {
                this.setState({
                    ...this.state,
                    locationDetails: JSON.parse(localStorage.getItem('user_location_details_local')).geoLocationDetails
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            validator: false
        });
    };

    clearForm = () => {
        const state = this.state;
        state.firstName = "";
        state.lastName = "";
        state.email = "";
        state.mobile = "";
        state.location = "";
        state.description = "";
        state.disabled = false;
        state.msgSent = false;
        this.setState(state);
    };

    checkStatus = (from) => {
        this.clearForm();
        if(from === 'mainForm'){
            this.consultationModal.hide();
        }else if (from === 'successForm') {
            this.successForm.hide();
        }
        this.props.router.push('/career-mapping?status=true');
    };

    onSubmit = () => {
        const parentThis = this;
        const state = parentThis.state;
        let flag = false;
        state.firstName = state.firstName.trim();
        state.lastName = state.lastName.trim();
        state.description = state.description.trim();
        if (validator.text(state.firstName)
          && validator.text(state.lastName)
          && validator.email(state.email)
          && validator.text(state.description)
        ) {
            if (state.locationDetails.country_code === 'IN') {
                flag = validator.mobile(state.mobile);
            }
            else {
                flag = true
            }
        }
        if (flag) {
            state.disabled = true;
            parentThis.setState(state);
            let mobile = state.mobile !== '' ? `+${state.locationDetails.calling_code} ${state.mobile}` : '';
            let input = {
                name: state.firstName + " " + state.lastName,
                email: state.email,
                mobile: mobile,
                location: state.location,
                description: "Consultation For: " + state.service.toUpperCase() + " - " + state.description,
                contactType: this.props.slackChannel==='kolkata-admission-leads'?'admission':'free_consultation'
            };
            post(`${process.env.NEXT_PUBLIC_API_URI}${process.env.NEXT_PUBLIC_API_VERSION}/contactUs?_format=json`, input, {}).then((res) => {
                if (res.code === "201" && res.status === "success") {
                    let data = {
                        fullName: state.firstName + " " + state.lastName
                    }
                    mailer.sendMail(state.email, "contactUs-mail", [], [], "You have Sent a Request.", false, data).then((res) => {
                        if (res.status === 'success' && res.code === '250') {
                            console.log("mail-sent");
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    try{
                        if(this.props.slackChannel){
                            SlackWebHook.sendSlackMessage(`New contact request from ${input.name} (${input.email} - ${input.mobile}) from ${input.location}`, 'Landing Page', ':computer', `${process.env.NEXT_PUBLIC_ENV === 'production'?this.props.slackChannel:process.env.NEXT_PUBLIC_SLACK_CHANNEL}`);
                        }
                        else{
                            SlackWebHook.sendSlackMessage(`New contact request from ${input.name} (${input.email} - ${input.mobile}) from ${input.location}`, 'Contact Us', ':computer', );
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                    let adminData = {
                        fullName: state.firstName + " " + state.lastName,
                        mail: state.email,
                        phone: mobile,
                        location: state.location,
                        message: "Consultation For: " + state.service.toUpperCase() + " - " + state.description
                    }
                    mailer.sendMail(process.env.NEXT_PUBLIC_ADMIN_EMAIL, "contactUsAdmin-mail", [], [], "New Contact Request", false, adminData).then((res) => {
                        if (res.status === 'success' && res.code === '250') {
                            console.log("mail-sent-admin");
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                    this.setState({
                        ...this.state,
                        msgSent: true,
                        disabled: false
                    }, () => {
                        this.clearForm();
                        this.consultationModal.hide();
                        this.props.router.push('/admission-success' + validator.queryParamBind(input));
                    });

                } else {
                    state.disabled = false;
                    parentThis.setState(state);
                    error('Something went wrong.');
                }
            }).catch(() => {
                state.disabled = false;
                parentThis.setState(state);
                error('Something went wrong.');
            });
        }
        else {
            state.validator = true;
            parentThis.setState(state);
        }
    };

    proceedToPay = () => {
        const parentThis = this;
        let {careerMappingPrice} = this.state;
        let firstName = this.state.firstName.trim();
        let lastName = this.state.lastName.trim();
        let email = (this.state.email.trim()).toLowerCase();
        let mobile = this.state.mobile.trim();
        this.setState({...this.state, validator: true});
        if(validator.text(firstName)
            && validator.text(lastName)
            && validator.email(email)
            && validator.mobile(mobile)
        ) {
            let input = {
                firstName: firstName,
                lastName: lastName,
                address:"",
                email: email,
                mobile: mobile,
                paymentGateway:"razorpay",
                amount:careerMappingPrice,
                countryCode:"IN"
            }
            try{
                SlackWebHook.sendSlackMessage(`${firstName} ${lastName}, ${email} (${mobile}) has initiated payment for career mapping from ${JSON.parse(localStorage.getItem('user_location_details_local')).geoLocationDetails.city}`, 'CareerMappingWebhook', ':male-student');
            }
            catch (e) {
                console.log(e);
            }
            CareerMappingAction.productPayment(input).then(function (res) {
                console.log(res);
                const response = res.data.CareerCounsellingPayment;
                if (response.status === 'success' && response.code === '201') {
                    const data = response.data;
                    var options = {
                        "key": process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                        "amount": data.orderAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise or INR 500.
                        "currency": "INR",
                        "name": firstName+' '+lastName,
                        "description": 'Career Mapping',
                        "image": "/images/favicon/favicon-128.png",
                        "order_id": data.razorpayOrderId,//This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
                        "handler": function (response) {
                            console.log(response);
                            var input = {
                                careerCounsellingId: data.careerCounsellingId,
                                razorpayPaymentId: response.razorpay_payment_id,
                                razorpayOrderId: response.razorpay_order_id,
                                razorpaySignature: response.razorpay_signature
                            }
                            console.log(input);
                            CareerMappingAction.paymentUpdate(input).then(function (res) {
                                console.log(res);
                                const response = res.data.CareerCounsellingPaymentUpdate;
                                if (response.status === 'success' && response.code === '200') {
                                    parentThis.setState({ disabled:false, validator:false, msgSent: false});
                                    parentThis.clearForm();
                                    parentThis.successForm.show();
                                    try {
                                        SlackWebHook.sendSlackMessage(`${firstName} ${lastName}, ${email} (${mobile}) has paid for career mapping`, 'CareerMappingWebhook', ':male-student');
                                    }
                                    catch (e) {
                                        console.log(e);
                                    }
                                } else {
                                    parentThis.setState({disabled:false, validator:false, msgSent: false});
                                    error(response.message);
                                }
                            }).catch(function (err) {
                                console.log(err);
                                error('Something went wrong!');
                            });
                        },
                        "modal": {
                            "ondismiss": function () {
                                console.log("Checkout form closed");
                                parentThis.setState({disabled:false, validator:false, msgSent: false});
                            }
                        },
                        "prefill": {
                            "name": firstName + ' ' + lastName,
                            "email": email,
                            "contact": mobile
                        },
                        "notes": {
                            "address": ""
                        },
                        "theme": {
                            "color": "#FCED04"
                        }
                    }
                    let rzp1 = new Razorpay(options);
                    rzp1.open();
                    // e.preventDefault();

                } else {
                    parentThis.setState({disabled:false, validator:false, msgSent: false});
                    console.log(response.message);
                    error(response.message);
                }
            }).catch(function (err) {
                console.log(err);
                parentThis.setState({disabled:false, validator:false, msgSent: false});
                error('Something went wrong!');
            });
        }
        else {
            warn('Please fill the mandatory fields');
        }
    }

    userChoice = (pay) => {
        this.consultationModal.hide();
        if(pay) {
            this.proceedToPay();
        }else{
            this.clearForm();
            this.props.router.push('/career-mapping');
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="freeConsultationModal" tabIndex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header d-md-none">
                                <h5 className="modal-title">Book a Consulation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body bg-primary-light p-0">
                                {/* <div className="flex-row d-block d-lg-flex"> */}
                                <div className="row mx-0">
                                    {/* <div className="consultation-modal-details bg-white p-4">  */}
                                    <div className="col-lg-4 p-4 bg-white">
                                        <CustomImage srcSetPaths={[{path: '/admission/book-consultation-illustration.svg?tr=w-272,bl-6,f-avif', type: 'image/avif'},{path: '/admission/book-consultation-illustration.svg?tr=w-272,bl-6,f-auto', type: 'image/webp'}]}
                                            mainSrc="/admission/book-consultation-illustration.svg?tr=w-272,bl-6,f-png" alt="Book a Consultation Illustration" width="272px" height="214px" className="mb-2 mb-md-5 mx-auto" imgCDN loading="lazy"/>

                                        <div>
                                            <h2 className="mb-4 d-none d-md-block text-lg-start text-md-center">Book a Consultation</h2>
                                            <p className='text-lg-start text-md-center'>Have a dream college that you want to get into, but confused with the process? No worries.<br/><br/>Book a consultation with us today and we will get back to you within 2 hours.</p>
                                        </div>
                                    </div>

                                    {/* {
                                        !this.state.msgSent ? */}
                                    {/* <div id="contact-us" className="flex-grow-1 p-4"> */}
                                    <div id="contact-us" className='col-lg-8 p-4'>
                                        <form id={'consultation_form'} onSubmit={(e) => e.preventDefault()}>
                                            <div className="row g-3 mb-4">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="firstName" className="form-label">Your First Name</label>
                                                    <input type="text" className={`form-control ${this.state.validator && (validator.text(this.state.firstName) ? "is-valid" : "is-invalid")}`} name="firstName" id="firstName" value={this.state.firstName} onChange={this.onChange.bind(this)} placeholder="Enter your first name" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="lastName" className="form-label">Your Last Name</label>
                                                    <input type="text" className={`form-control ${this.state.validator && (validator.text(this.state.lastName) ? "is-valid" : "is-invalid")}`} name="lastName" id="lastName" value={this.state.lastName} onChange={this.onChange.bind(this)} placeholder="Enter your last name" />
                                                </div>
                                            </div>
                                            <div className="row g-3 mb-4">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <input type="email" className={`form-control ${this.state.validator && (validator.email(this.state.email) ? "is-valid" : "is-invalid")}`} name="email" id="email" value={this.state.email} onChange={this.onChange.bind(this)} placeholder="Enter your email" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="mobile" className="form-label">Phone Number{this.state.locationDetails.country_code !== 'IN' && ' (Optional)'}</label>
                                                    <input type="tel" className={`form-control ${this.state.locationDetails.country_code === 'IN' && this.state.validator && (validator.mobile(this.state.mobile) ? "is-valid" : "is-invalid")}`} name="mobile" id="mobile" value={this.state.mobile} onChange={this.onChange.bind(this)} placeholder="Enter your phone number" />
                                                </div>
                                            </div>
                                            <div className="row g-3 mb-4">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="service" className="form-label">Consultation Type</label>
                                                    <select name="service" className="form-select" id="service"
                                                        value={this.props.service}
                                                        onChange={this.onChange.bind(this)}>
                                                        <option value="undergraduate">Undergraduate</option>
                                                        <option value="postgraduate">Postgraduate</option>
                                                        <option value="mba">MBA</option>
                                                        <option value="classroom-coaching">Classroom Coaching</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="location" className="form-label">Location</label>
                                                    <input type="text" className={`form-control`} id="location" name="location" value={this.state.location} onChange={this.onChange.bind(this)} placeholder="Where are you based?" />
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <label htmlFor="description" className='form-label'>What can we help you with?</label>
                                                <textarea rows="6" className={`form-control ${this.state.validator && (validator.text(this.state.description) ? "is-valid" : "is-invalid")}`} id="description" name="description" value={this.state.description} onChange={this.onChange.bind(this)} placeholder="Give us a short description of your requirement and we'll get back to you" />
                                            </div>
                                            {/* <div className="flex-row justify-content-center mb-3">
                                                        <div className="flex-column flex-md-row align-items-center">Already paid for a consultation? &nbsp; <a className='text-primary pointer d-block' onClick={this.checkStatus.bind(this, 'mainForm')}> Check Status</a></div>
                                                    </div> */}
                                            <div className="flex-column flex-md-row justify-content-md-between align-items-center">
                                                <a href="#" className="pointer d-none d-md-block text-body pe-auto" data-bs-dismiss="modal" onClick={this.clearForm}>Cancel</a>
                                                <div className="flex-column flex-md-row justify-content-md-end align-items-center">
                                                    <button
                                                        id="confirm_consultation"
                                                        className="btn btn-primary text-white confirm_consultation"
                                                        disabled={this.state.disabled} onClick={this.onSubmit.bind(this)}>
                                                        {this.state.disabled ? 'Sending Message...' : 'Send Message'}
                                                        {!this.state.disabled && <MdArrowForward className="ms-2" />}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* :
                                             <div className="flex-grow-1 p-4">
                                                 <div className="flex-center h-100">
                                                     <div style={{border: "2px dashed grey", padding: "1rem"}}>
                                                         <h4 className="mb-5 text-center">Hey {this.state.firstName}, Would you like to schedule a paid Career Mapping session?</h4>
                                                         <div className="d-flex justify-content-around">
                                                             <button className="btn btn-outline-danger" onClick={this.userChoice.bind(this, false)}><FaTimes />&nbsp; No</button>
                                                             <button className="btn btn-outline-success" onClick={this.userChoice.bind(this, true)}><FaCheck />&nbsp; Yes</button>
                                                         </div>
                                                    </div>
                                                 </div>
                                             </div> */}
                                    {/* } */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="successForm" tabIndex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="SuccessForm" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="px-2 py-5">
                                <div className="mx-auto text-center mb-3">
                                    <CustomImage srcSetPaths={[{path: '/admission/success.png?tr=w-250,f-avif', type: 'image/avif'},{path: '/admission/success.png?tr=w-250,f-auto', type: 'image/webp'}]}
                                        mainSrc="/admission/success.png?tr=w-250,f-png" alt="Success Icon" width="250px" height="auto" imgCDN loading="eager" className="darken" />

                                    <h4 className="text-center mt-3">Registration Successful!</h4>
                                    <h3 className="text-center font-weight-bold mt-3">We will contact you within
                                    24 hours.</h3>
                                </div>
                                <button className="btn btn-lg btn-primary px-5 mx-auto" data-bs-dismiss="modal" onClick={this.checkStatus.bind(this, 'successForm')}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(FreeConsultationModal);
