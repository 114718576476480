import { get } from "./fetch"
import { error } from "../components/Toast";

const ipdata = {

    defaultLocation() {
        return (
            {"count":0,"is_default":true,"ip":"203.171.246.53","is_eu":false,"city":"Kolkata","region":"West Bengal","region_code":"WB","country_name":"India","country_code":"IN","continent_name":"Asia","continent_code":"AS","latitude":22.518,"longitude":88.3832,"postal":"700059","calling_code":"91","flag":"https://ipdata.co/flags/in.png","emoji_flag":"🇮🇳","emoji_unicode":"U+1F1EE U+1F1F3","asn":{"asn":"AS23860","name":"Alliance Broadband Services Pvt. Ltd.","domain":"alliancebroadband.co.in","route":"203.171.246.0/24","type":"isp"},"languages":[{"name":"Hindi","native":"हिन्दी"},{"name":"English","native":"English"}],"currency":{"name":"Indian Rupee","code":"INR","symbol":"Rs","native":"টকা","plural":"Indian rupees"},"time_zone":{"name":"Asia/Kolkata","abbr":"IST","offset":"+0530","is_dst":false,"current_time":"2021-07-13T16:11:38.304146+05:30"},"threat":{"is_tor":false,"is_proxy":false,"is_anonymous":false,"is_known_attacker":false,"is_known_abuser":false,"is_threat":false,"is_bogon":false},"count":"100"}
        )
    },

    getLocation(){
        return new Promise((resolve, reject) => {
            try {
                let defaultLocationData = this.defaultLocation();
                var geoApiCall = false;
                if (JSON.parse(localStorage.getItem('user_location_details_local')) == null) {
                    geoApiCall = true;
                }
                else if ((JSON.parse(localStorage.getItem('user_location_details_local'))).timestamp == undefined) {
                    geoApiCall = true;
                }
                else if ((Math.round((new Date).getTime() / 1000)) > (Math.round(((JSON.parse(localStorage.getItem('user_location_details_local'))).timestamp) / 1000) + (24 * 3600))) {
                    geoApiCall = true;
                }
                else if(JSON.parse(localStorage.getItem("user_location_details_local"))?.geoLocationDetails?.is_default){
                    defaultLocationData = JSON.parse(localStorage.getItem("user_location_details_local"))?.geoLocationDetails;
                    if(defaultLocationData.count >=3){
                      if(defaultLocationData.count <=5){
                        defaultLocationData.count = parseInt(defaultLocationData.count)+1;
                        error('Location Mismatch! Contact us for the pricing in your location.');
                        geoApiCall = true;
                      }
                    }
                    else{
                      defaultLocationData.count = parseInt(defaultLocationData.count)+1;
                      geoApiCall = true;
                    }
                  }
                if (geoApiCall) {
                    get(`https://api.ipdata.co?api-key=${process.env.NEXT_PUBLIC_LOCATION_API_KEY}`, null, null)
                    .then((res) => {
                        if(!(res instanceof Error)) {
                          localStorage.setItem(
                            "user_location_details_local",
                            JSON.stringify({
                              geoLocationDetails: res,
                              timestamp: new Date().getTime(),
                            })
                          );
                          resolve(true);
                        } else {
                          localStorage.setItem(
                            "user_location_details_local",
                            JSON.stringify({
                              geoLocationDetails: defaultLocationData,
                              timestamp: new Date().getTime(),
                            })
                          );
                          resolve(true);
                        }
                    })
                    .catch(error => reject(error.message))
                }else{
                    resolve(true);
                }
            }catch (e) {
                reject(e.message);
            }
        });
    }

}

export default ipdata;
