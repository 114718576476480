import { post } from "./fetch"

const slackWebHook = {
    sendSlackMessage(
        text,
        username = process.env.NEXT_PUBLIC_SLACK_USERNAME,
        icon_emoji = ":scream:",
        channel = process.env.NEXT_PUBLIC_SLACK_CHANNEL,
        slackUrl = process.env.NEXT_PUBLIC_SLACK_URL
    ) {
        let message = { text, channel, username, icon_emoji };
        return post(`${slackUrl}`, message, null)
            .then(res => res.data)
            .catch(error => error);
    },
};

export default slackWebHook;
