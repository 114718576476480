import { post } from "./fetch"

const mailer = {

    // request api to send mail
    // @ author: Arijit Deb Roy
    // @ params: mailType, to, cc[], bcc[], subject, attachment(0/1) data{}

    sendMail(to, mailType, cc, bcc, subject, attachmentStatus = false, data) {
        const sendData = {
            "mailType" : mailType,
            "to" : to,
            "cc" : cc,
            "bcc" : bcc,
            "subject" : subject,
            "attachment": attachmentStatus,
            "data": data
        }
        return post(`${process.env.NEXT_PUBLIC_MAIL_URL}/mail?_format=json`, sendData, {})
            .then(res => res)
            .catch(error => error);
    }
}

export default mailer;
