
const validator = {

    email(data) {
        var flag = false;
        if(data.length > 0){
            if (data.match(/^[a-z0-9._%+-]{1,64}@(?:[a-z0-9-]{1,63}\.){1,4}[a-z]{2,5}$/)) {
                flag = true;
            }
        }
        return flag;
    },

    positiveNumber(n, lowerLimit = 0, uperLimit = 1000000000) {
        if(n !== '' && n !== null && parseInt(n) > lowerLimit && parseInt(n) < uperLimit){
            return true;
        }
        else{
            return false;
        }
    },

    mobile(data) {
        var flag = false;
        if(data.length > 0){
            if (data.match(/^[0-9]+$/) && data.length >= 8 && data.length <= 17) {
                flag = true;
            }
        }
        return flag
    },

    text(data) {
        var flag = false;
        if(data !== null && data !== undefined && data !== ""){
            flag = true;
        }
        return flag
    },

    queryParamBind(obj, pageValue = 0){
        let qArr = [] , url = "";
        Object.entries(obj).forEach((value) => {
            if(value[1] !== "" && value[1] !== undefined && value[1] !== null){
                if(Array.isArray(value[1])){
                    qArr.push(value[0] + '=' + value[1].join(","));
                }else{
                    qArr.push(value[0] + '=' + value[1]);
                }
            }
        })
        if(qArr.length > 0 && pageValue != 0){
            url = "?"+qArr.join('&').concat("&page="+(pageValue));
        }else if(qArr.length == 0 && pageValue != 0){
            url = "?page="+(pageValue)
        }else if(qArr.length > 0){
            url = "?"+qArr.join('&')
        }
        return url;
    },

    detectBrowser(){
                 
        let userAgent = navigator.userAgent;
        let browserName = "No browser detection";
        
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "chrome";
          }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "firefox";
          }  else if(userAgent.match(/safari/i)){
            browserName = "safari";
          }else if(userAgent.match(/opr\//i)){
            browserName = "opera";
          } else if(userAgent.match(/edg/i)){
            browserName = "edge";
          }
          
        return browserName       
    },

}

export default validator;
