import gql from 'graphql-tag';
import Client from "../services/client";

const CareerMappingAction = {

    // Product list
    // @ author: Chandan Kumar Gupta
    // @ params:

    productPaymentOffline(input){
        const PRODUCT_PAYMENT = gql`
            mutation CareerCounsellingOffline($input: CareerCounsellingPaymentInput!){
              CareerCounsellingOffline(input: $input){
                code
                status
                message
                data{
                  careerCounsellingId
                  paymentStatus
                  razorpayOrderId
                  orderAmount
                }
              }
            }
        `;
        return Client.mutate({
            mutation: PRODUCT_PAYMENT,
            variables: {
                input: input
            }
        });
    },
    productPayment(input){
        const PRODUCT_PAYMENT = gql`
            mutation CareerCounsellingPayment($input: CareerCounsellingPaymentInput!){
              CareerCounsellingPayment(input: $input){
                code
                status
                message
                data{
                  careerCounsellingId
                  paymentStatus
                  razorpayOrderId
                  orderAmount
                }
              }
            }
        `;
        return Client.mutate({
            mutation: PRODUCT_PAYMENT,
            variables: {
                input: input
            }
        });
    },
    paymentUpdate(input){
        const PAYMENT_VERIFY = gql`
            mutation CareerCounsellingPaymentUpdate($input: CareerCounsellingPaymentUpdateInput!){
              CareerCounsellingPaymentUpdate(input: $input){
                code
                status
                message
              }
            }
        `;
        return Client.mutate({
            mutation: PAYMENT_VERIFY,
            variables: {
                input: input
            }
        });
    },
    transactionList(filters, pagination) {
        const PRODUCT_USER_PAYMENT_LIST = gql`
        query careerCounsellingTransactionList($input: CareerCounsellingTransactionInput!) {
          careerCounsellingTransactionList(input: $input) {
            code
            status
            message
            data {
              transactionList{
                careerCounsellingId
                firstName
                lastName
                email
                address
                mobile
                status
                paymentGateway
                paymentgatewayTxnid
                amount
                paymentGatewayResponse
                adminComments
                createdAt
                updatedAt
              }
              totalCount
            }
          }
        }
    `;
        return Client.query({
            query: PRODUCT_USER_PAYMENT_LIST,
            variables: {
                input: {
                    filters: filters,
                    pagination: pagination,
                },
            }
        });
    },
}

export default CareerMappingAction;
